import './vendor/flickity/flickity';
import 'smoothscroll-polyfill/dist/smoothscroll.min.js'
import './vendor/aos/aos';
import './vendor/bootstrap/bootstrap';
import './vendor/flatpickr/flatpickr';
import './vendor/ja/ja';
import './vendor/scrollbooster/scrollbooster';
import './vendor/upward/index';
import './utility/inputs/number-fix';
import './utility/forms/form-guard';
import './utility/modals/backdrop-fix';

// Included css framework layout
window.addEventListener("load", function() {
    setTimeout(function() {
        document.querySelector('body').classList.add('loaded');
    }, 300);
});
